body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 0.275rem;
  height: 0.375rem;
}

*::-webkit-scrollbar-track {
  border-radius: 0.625rem;
  background: rgba(104, 102, 216, 0.06);
}

*::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  background: rgba(104, 102, 216, 0.8);
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(104, 102, 216, 0.9);
}

*::-webkit-scrollbar-thumb:active {
  background: rgba(104, 102, 216, 0.9);
}

@media (max-width: 900px) {
  *::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}

div {
  scroll-behavior: smooth;
}